.content {
  width: 35rem;
  display: flex;
  margin: 2rem 0;
}

.select {
  /* margin-top: 2rem; */
  width: 28rem;
  height: 2.4rem;
  font-size: 1rem;
  outline: 0;
  border: 1px solid #27ae70;
}

.button {
  height: 2.4rem;
  /* width: 5rem; */
  background: #27ae70;
  outline: 0;
  border: 1px solid #27ae70;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  padding: 0 1rem;
}

.button:hover {
  background: #fff;
  color:  #27ae70;
  transition: .3s;
}

.itemTable {
  display: block;
  padding: 2px;
}

/* CheckBox */


.containerCheckbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 1.1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #ccc;
}

.containerCheckbox input:checked ~ .checkmark {
  background-color: #27ae70;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerCheckbox input:checked ~ .checkmark:after {
  display: block;
}

.containerCheckbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}