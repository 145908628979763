.button {
  height: 4.5rem;
  /* width: 5rem; */
  background: #27ae70;
  outline: 0;
  border: 1px solid #27ae70;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  padding: 0 2.5rem;
  margin: 3rem 3rem 0 0;
}

.button:hover {
  background: #fff;
  color:  #27ae70;
  transition: .3s;
}