.content {
  width: 35rem;
  display: flex;
  margin: 2rem 0;
}

.select {
  /* margin-top: 2rem; */
  width: 28rem;
  height: 2.4rem;
  font-size: 1rem;
  outline: 0;
  border: 1px solid #27ae70;
}

.button {
  height: 2.4rem;
  /* width: 5rem; */
  background: #27ae70;
  outline: 0;
  border: 1px solid #27ae70;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  padding: 0 1rem;
}

.button:hover {
  background: #fff;
  color:  #27ae70;
  transition: .3s;
}

.itemTable {
  display: block;
  padding: 2px;
}

hr {
  border: 1px solid #27ae6f67;
}