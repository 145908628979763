.header {
  background: #27ae70;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoBytebio {
  width: 12rem;
  padding: 0.5rem 0;
}

.logoExtractView {
  font-size: 1.6rem;
  font-weight: 600;
  color: #fff;
}

.link {
  text-decoration:none;
}